export const filelist = [
    {
        name: "1 Informationsschreiben",
        path: "/documents/info_writing_for_20241215/1_Informationsschreiben.pdf"
    },
    {
        name: "2 Lohntabelle 2025",
        path: "/documents/info_writing_for_20241215/2_Lohntabelle_2025.pdf"
    },
    {
        name: "3 Steuersatzmeldung 2025",
        path: "/documents/info_writing_for_20241215/3_Steuersatzmeldung_2025.pdf"
    },
    {
        name: "4 Steuersatzmeldung 2025 - Word",
        path: "/documents/info_writing_for_20241215/4_Steuersatzmeldung_2025.docx"
    },
    {
        name: "5 Katholikenzahlmeldung",
        path: "/documents/info_writing_for_20241215/5_Katholikenzahlmeldung.pdf"
    },
    {
        name: "6 Katholikenzahlmeldung - Word",
        path: "/documents/info_writing_for_20241215/6_Katholikenzahlmeldung.docx"
    },
    {
        name: "7 Terminliste Kirchgemeinden 2024",
        path: "/documents/info_writing_for_20241215/7_Terminliste_Kirchgemeinden_2024.pdf"
    },
    {
        name: "8 PersonalMonitoring Bistum Chur Schwyz 2024",
        path: "/documents/info_writing_for_20241215/8_PersonalMonitoring_Bistum_Chur_Schwyz_2024.pdf"
    },
    {
        name: "9 Dankesbrief Personalumfrage",
        path: "/documents/info_writing_for_20241215/9_Dankesbrief_Personalumfrage.pdf"
    },
]