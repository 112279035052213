import { filelist } from "./filelists/filelist20241215"

export default {
    id: 34,
    name: "20241215InformationWriting",
    type: "InformationWrting",
    title: "Informationsschreiben und Besoldungsentscheide des Kantonalen Kirchenvorstandes per 1. Januar 2025",
    subtitle: "(Teuerungsausgleich / Lohntabellen / Meldung Steuerfuss / Meldung Katholikenzahl, Terminliste für Kirchgemeinden, Personal Monitoring)",
    value: `
Der Kantonale Kirchenvorstand beschloss: <br>
Die “Lohnklassen und Lohnstufen” gemäss Anhang 2 der Personal- und Besoldungsverordnung werden per 1. Januar 2025 für das Jahr 2025 auf den Indexstand von 109.3 Punkten (auf der Basis Dezember 2005 = 100 Punkte) erhöht. <br>
Die Kirchgemeinden haben den beschlossenen Steuerfuss für das Jahr 2025 mittels des Formulars bis allerspätestens am 23. Dezember 2024 zu melden. <br>
Die Katholikenzahlen per 1. Januar 2025 etc. sind mittels des Formulars bis am 31. Januar 2025 von den Kirchgemeinden zu melden. <br>
Die Terminliste für die Kirchgemeinden wurde aktualisiert. <br>
Das Personal Monitoring für das Bistum Chur wurde abgeschlossen und die Ergebnisse auch betreffend des Kantons Schwyz liegen vor. Die Mitarbeit wird mit einem separaten Brief bestens verdankt. 
    `,
    target: "InfoSite",
    Post: true,
    date: 20241215,
    files: {
        filesTitle: "Brief und Formulare:",
        filesList: filelist
    }
}
